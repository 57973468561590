// Generated by Framer (1b127cb)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useActiveVariantCallback, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const cycleOrder = ["Dlsv7jYp3"];

const variantClassNames = {Dlsv7jYp3: "framer-v-1sit4qy"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

const transformTemplate = (_, t) => `translate(-50%, -50%) ${t}`

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; title?: string; tap?: any }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "Dlsv7jYp3", title: gEhG2Y5cq = "Shop", tap: AxhA3IxiR, ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "Dlsv7jYp3", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTap1xio9v4 = activeVariantCallback(async (...args) => {
if (AxhA3IxiR) {
const res = await AxhA3IxiR(...args);
if (res === false) return false;
}
})

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-ooIoN", classNames)} style={{display: "contents"}}>
<motion.div {...restProps} className={cx("framer-1sit4qy", className)} data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"Dlsv7jYp3"} onTap={onTap1xio9v4} ref={ref} style={{...style}} transition={transition}><RichText __fromCanvasComponent children={<React.Fragment><motion.p>Shop</motion.p></React.Fragment>} className={"framer-1lp129o"} layoutDependency={layoutDependency} layoutId={"RYeZ1J6JO"} style={{"--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline", "--framer-paragraph-spacing": "0px"}} text={gEhG2Y5cq} transformTemplate={transformTemplate} transition={transition} verticalAlignment={"top"} withExternalLayout/></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-ooIoN [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-ooIoN .framer-2r1xvo { display: block; }", ".framer-ooIoN .framer-1sit4qy { cursor: pointer; height: 19px; overflow: visible; position: relative; width: 39px; }", ".framer-ooIoN .framer-1lp129o { flex: none; height: auto; left: 49%; position: absolute; top: 50%; white-space: pre; width: auto; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 19
 * @framerIntrinsicWidth 39
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"gEhG2Y5cq":"title","AxhA3IxiR":"tap"}
 */
const FramerMAvDSRuW_: React.ComponentType<Props> = withCSS(Component, css, "framer-ooIoN") as typeof Component;
export default FramerMAvDSRuW_;

FramerMAvDSRuW_.displayName = "item-menu-shop";

FramerMAvDSRuW_.defaultProps = {height: 19, width: 39};

addPropertyControls(FramerMAvDSRuW_, {gEhG2Y5cq: {defaultValue: "Shop", displayTextArea: false, title: "Title", type: ControlType.String}, AxhA3IxiR: {title: "Tap", type: ControlType.EventHandler}} as any)

addFonts(FramerMAvDSRuW_, [])